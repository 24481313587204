<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">
        <app-i18n code="home.menu"></app-i18n>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <app-i18n code="entities.vorlageDienstgeber.menu"></app-i18n>
      </el-breadcrumb-item>
    </el-breadcrumb>

    <div class="app-content-page">
      <h1 class="app-content-title">
        <app-i18n code="entities.vorlageDienstgeber.list.title"></app-i18n>
      </h1>

      <app-vorlage-dienstgeber-list-toolbar></app-vorlage-dienstgeber-list-toolbar>
      <app-vorlage-dienstgeber-list-filter></app-vorlage-dienstgeber-list-filter>
      <app-vorlage-dienstgeber-list-table></app-vorlage-dienstgeber-list-table>
    </div>
  </div>
</template>

<script>
import VorlageDienstgeberListFilter from '@/modules/vorlage-dienstgeber/components/vorlage-dienstgeber-list-filter.vue';
import VorlageDienstgeberListTable from '@/modules/vorlage-dienstgeber/components/vorlage-dienstgeber-list-table.vue';
import VorlageDienstgeberListToolbar from '@/modules/vorlage-dienstgeber/components/vorlage-dienstgeber-list-toolbar.vue';

export default {
  name: 'app-vorlage-dienstgeber-list-page',

  components: {
    [VorlageDienstgeberListFilter.name]: VorlageDienstgeberListFilter,
    [VorlageDienstgeberListTable.name]: VorlageDienstgeberListTable,
    [VorlageDienstgeberListToolbar.name]: VorlageDienstgeberListToolbar,
  },
};
</script>

<style></style>
